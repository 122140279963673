import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav, Row, Col, Image, Carousel } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FAQ, Events } from './App';
import { BsDiamondFill } from 'react-icons/bs';
import { useSearchParams } from "react-router-dom";
import { useToken } from './auth/useToken';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';

export function Current({twitchDrops=[]}) {
    let dropsTimer = null;
    const [tick, setTick] = useState(0);
    useEffect(() => {
        let tickVal = 0;
        dropsTimer = setInterval(() => {
            if( twitchDrops == null) return null;
            tickVal += 1;
            setTick(tickVal);
        }, 1000);
        return () => {
            clearInterval(dropsTimer);
        }
    }, []);
    
    return (
        <div className = "mainContent background">
            <NavigationBar/>
            <Container fluid className="ps-0 pe-0 twitchDropSection">
                <Container fluid className="pt-3" style={{margin: "0px"}}>
                    <Image src="/images/twitchDrops.png" className='twitchDropLogo'/>
                </Container>
                    <Container style={{paddingRight: "12px" , paddingLeft: "12px"}}>
                        <div className="winter-fever-drops">
                            <PageTitle title={twitchDrops.length > 0 ? twitchDrops[0].name : "CURRENT DROPS"}/>
                        </div>
                    </Container>    
                <Container className="py-3 twitchDropsContent">
                    {twitchDrops.length > 0 ? 
                        <>
                            {twitchDrops.length > 1 ? 
                            <Carousel variant="dark">
                                { twitchDrops.map((event, i) => { return ( 
                                    <Carousel.Item key={i}>
                                        <Events key={i} info={event} />
                                    </Carousel.Item>
                                )}) }   
                            </Carousel>
                            :
                            <>
                                { twitchDrops.map((event, i) => { return ( 
                                        <Events key={i} info={event} />
                                )}) }   
                            </>
                        }
                        </>
                    :
                        <Container fluid className="rounded-3">
                            <Row className="rounded-3 pt-3 pb-3 g-4 m-auto mb-3 justify-content-center" style={{backgroundColor:"rgba(43, 37, 37, 0.75)", color:"white", fontSize:"20px", fontFamily:"Timeless-Normal"}}>
                                <p className="m-auto yellowTextColor" style={{fontSize:"35px"}}>No Drops Available</p>
                                <Container className="d-flex align-items-center mt-0 justify-content-center">
                                    <BsDiamondFill className='yellowTextColor'/> 
                                    <p className="my-0 mx-2"> Check Future Drops for upcoming events! </p>
                                    <BsDiamondFill className='yellowTextColor'/>
                                </Container>
                            </Row> 
                        </Container>
                    }                           
                </Container>
            </Container>
            <Footer />
        </div>
    )
}

export function Past({pastDrops=[]}) {
    return (
        <div className="mainContent backgroundPast">
            <NavigationBar />

            <Container fluid className="ps-0 pe-0 backgroundPast">
                <Container fluid className="pt-3" style={{margin: "0px"}}>
                    <Image src="/images/twitchDrops.png" className='twitchDropLogo'/>
                </Container>
                <Container style={{paddingRight: "12px" , paddingLeft: "12px"}}>
                   <div className="winter-fever-drops">
                        <PageTitle title="PAST EVENTS"/>
                   </div>
                </Container>    
                <Container className="py-3 px-1">
                    {pastDrops.length > 0 ? 
                        <>
                            {pastDrops.length > 1 ? 
                            <Carousel variant="dark">
                                { pastDrops.map((event, i) => { return ( 
                                    <Carousel.Item key={i}>
                                        <Events key={i} info={event} />
                                    </Carousel.Item>
                                )}) }   
                            </Carousel>
                            :
                            <>
                                { pastDrops.map((event, i) => { return ( 
                                        <Events key={i} info={event} />
                                )}) }   
                            </>
                        }
                        </>
                    :
                        <Container fluid className="rounded-3">
                            <Row className="rounded-3 pt-3 pb-3 g-4 m-auto mb-3 justify-content-center" style={{backgroundColor:"rgba(43, 37, 37, 0.75)", color:"white", fontSize:"20px", fontFamily:"Timeless-Normal"}}>
                                <p className="m-auto yellowTextColor" style={{fontSize:"35px"}}>No Drops Available</p>
                                <Container className="d-flex align-items-center mt-0 justify-content-center">
                                    <BsDiamondFill className='yellowTextColor'/> 
                                    <p className="my-0 mx-2"> Check again soon for event history! </p>
                                    <BsDiamondFill className='yellowTextColor'/>
                                </Container>
                            </Row> 
                        </Container>
                    }                 
                </Container>
            </Container>

            <Footer />
        </div>
    )
}

export function Future({futureDrops=[]}) {
    let dropsTimer = null;
    const [tick, setTick] = useState(0);
    useEffect(() => {
        let tickVal = 0;
        dropsTimer = setInterval(() => {
            if( futureDrops == null) return null;
            tickVal += 1;
            setTick(tickVal);
        }, 1000);
        return () => {
            clearInterval(dropsTimer);
        }
    }, []);
    
    return (
        <div className="mainContent backgroundFuture">
            <NavigationBar />
            <Container fluid className="ps-0 pe-0 backgroundFuture">
                <Container fluid className="pt-3" style={{margin: "0px"}}>
                    <Image src="/images/twitchDrops.png" className='twitchDropLogo'/>
                </Container>
                <Container style = {{paddingRight: "12px" , paddingLeft: "12px"}}> 
                    <div className="winter-fever-drops">
                        <PageTitle title="FUTURE EVENTS"/>
                    </div>
                </Container>   
                <Container className="py-3 px-1">
                    {futureDrops.length > 0 ? 
                        <>
                            {futureDrops.length > 1 ? 
                            <Carousel variant="dark">
                                { futureDrops.map((event, i) => { return ( 
                                    <Carousel.Item key={i}>
                                        <Events key={i} info={event} />
                                    </Carousel.Item>
                                )}) }   
                            </Carousel>
                            :
                            <>
                                { futureDrops.map((event, i) => { return ( 
                                        <Events key={i} info={event} />
                                )}) }   
                            </>
                        }
                        </>
                    :
                        <Container fluid className="rounded-3">
                            <Row className="rounded-3 pt-3 pb-3 g-4 m-auto mb-3 justify-content-center" style={{backgroundColor:"rgba(43, 37, 37, 0.75)", color:"white", fontSize:"20px", fontFamily:"Timeless-Normal"}}>
                                <p className="m-auto yellowTextColor" style={{fontSize:"35px"}}>No Drops Available</p>
                                <Container className="d-flex align-items-center mt-0 justify-content-center">
                                    <BsDiamondFill className='yellowTextColor'/> 
                                    <p className="my-0 mx-2"> Check again soon for upcoming events! </p>
                                    <BsDiamondFill className='yellowTextColor'/>
                                </Container>
                            </Row> 
                        </Container>
                    }                 
                </Container>
            </Container>
            <Footer />
        </div>
    )
}

export function FAQS({faqs=[]}) {
    return (
        <div className="mainContent backgroundFaq">
                <NavigationBar/>
            <Container fluid className="ps-0 pe-0 backgroundFaq">
                <Container fluid className="pt-3" style={{margin: "0px"}}>
                    <Image src="/images/twitchDrops.png" className='twitchDropLogo'/>
                </Container>
                    <Container style={{paddingRight: "12px" , paddingLeft: "12px"}}>
                        <div className='winter-fever-drops'>
                            <PageTitle title="FREQUENTLY ASKED QUESTIONS"/>
                        </div>
                    </Container>
                    <Container className="pt-3 rounded-3" >
                        {faqs.length > 0 && faqs.map( (faq, i) => { return <FAQ key={i} info={faq} />})}
                </Container>
            </Container>
            <Footer />
        </ div>
    )
}

export function Accounts() {
    const history = useNavigate()
    
    const [twitchOauthURL, setTwitchOauthURL] = useState('');
    const [steamAuthURL, setSteamAuthURL] = useState('');
    const [XBoxOauthURL, setXBoxOauthURL] = useState('');
    const [playstationOauthURL, setPlaystationOauthURL] = useState('');
    const [nintendoOauthURL, setNintendoOauthURL] = useState('');
    const [searchParams] = useSearchParams();
    const codeQueryParam = searchParams.get("code");
    const steamIdQueryParam = searchParams.get("openid.claimed_id");
    useEffect(() => {
        if( steamIdQueryParam != null) {
            let lastSlashCharPos = steamIdQueryParam.lastIndexOf("/");
            var accessCode = steamIdQueryParam.substring(lastSlashCharPos+1);
        }
        else if( codeQueryParam != null) {
            accessCode = codeQueryParam;
        }
        let platToAuth = localStorage.getItem("plat");

        if( accessCode != null && platToAuth != null) {
            history('/linkaccounts');
            fetch(platToAuth, { 
                method: 'POST',
                headers: {
                    'code' : accessCode,
                },
            })
            .then((response) => response.json())
            .then((jsonData) => { 
                if( jsonData.user_info) {
                    setToken(JSON.stringify(jsonData.user_info));
                }
            })
            .catch(e => {
                //setToken(null);
                console.log(e);
            })
        }
    }, []);
  
    const [token, setToken] = useToken();

    useEffect(() => {
        fetch('/auth/twitch/url')
        .then((response) => response.json())
        .then(setTwitchOauthURL)
    }, []);

    useEffect(() => {
        fetch('/auth/steam/url')
        .then((response) => response.json())
        .then(setSteamAuthURL)
    }, []);

    useEffect(() => {
        fetch('/auth/XBox/url')
        .then((response) => response.json())
        .then(setXBoxOauthURL)
    }, []);

    useEffect(() => {
        fetch('/auth/playstation/url')
        .then((response) => response.json())
        .then(setPlaystationOauthURL)
    }, []);

    useEffect(() => {
        fetch('/auth/nintendo/url')
        .then((response) => response.json())
        .then(setNintendoOauthURL)
    }, []);

    const logoutSteam = () => {
    fetch('/auth/steam/logout', { 
            method: 'POST', 
    })
    .then((response) => response.json())
    .then((jsonData) => { 
        if( jsonData.user_info ) {
            setToken(JSON.stringify(jsonData.user_info));
        }
        else {
            setToken(null);
        }
    })
    .catch(e => {
        console.log(e);
        setToken(null)
        })
    }

    const logoutPlaystation = () => {
    fetch('/auth/playstation/logout', { 
            method: 'POST',
    })
    .then((response) => response.json())
    .then((jsonData) => { 
        if( jsonData.user_info ) {
            setToken(JSON.stringify(jsonData.user_info));
        }
        else {
            setToken(null);
        }
    })
    .catch(e => {
        console.log(e);
        setToken(null)
        })
    }

    const logoutNintendo = () => {
        fetch('/auth/nintendo/logout', { 
                method: 'POST',
        })
        .then((response) => response.json())
        .then((jsonData) => { 
            if( jsonData.user_info ) {
                setToken(JSON.stringify(jsonData.user_info));
            }
            else {
                setToken(null);
            }
        })
        .catch(e => {
            console.log(e);
            setToken(null)
            })
        }
    
    const logoutXBox = () => {
    fetch('/auth/xbox/logout', { 
            method: 'POST',
    })
    .then((response) => response.json())
    .then((jsonData) => { 
        if( jsonData.user_info ) {
            setToken(JSON.stringify(jsonData.user_info));
        }
        else {
            setToken(null);
        }
    })
    .catch(e => {
        console.log(e);
        setToken(null)
        })
    }

    const unlinkTwitch = () => {
        fetch('/auth/twitch/unlink', { 
            method: 'POST',
        })
        .then((response) => { 
            
        })
        .catch(e => {
            console.log("error removing token on loging out of twitch " + e);
        })
        .finally( () => {
            setToken(null)
        });
    }

    const logoutTwitch = () => {
        fetch('/auth/twitch/logout', { 
            method: 'POST',
        })
        .then((response) => { 
            
        })
        .catch(e => {
            console.log("error removing token on loging out of twitch " + e);
        })
        .finally( () => {
            setToken(null)
        });
    }
    
    var status = 0;
    if( token != null ) {
        if(JSON.parse(token).steamid != null || JSON.parse(token).xboxid != null || JSON.parse(token).psid != null ) {
            status = 2;
        }
        else {
            status = 1;
        }
    }
    const statusMsg = ["Sign into your Twitch Account and Platform accounts",
                       "Sign into your Platform accounts where you own the game, or may purchase in future",
                       "Your drop will be available in game after earning and claiming the drop"];
    const statusValue = ["NOT ENABLED", "NOT ENABLED", "ENABLED"];
    return (
        <div className="mainContent backgroundAccount">
            <NavigationBar/>
            <Container fluid className="ps-0 pe-0 pb-5 align-items-center">
                <Container className="mt-5 winter-fever-drops" style={{maxWidth: "910px"}}>
                    <PageTitle title="LINK ACCOUNTS"/>
                </Container>  
                <Container className="mt-5 rounded-3 linkAccountContent">
                    <Row className="pt-3" id="twitch">
                            <div id="sign-in">
                                <h4 className="pb-2 normalText"><b style={{color:"#FFE1AD"}}>Step 1</b>: Sign in to your Twitch account</h4>
                                {token != null ?
                                    <>
                                    <img className="img" src="/images/twitch-logo.png"/>
                                    <span className="account">{JSON.parse(token).display_name}</span>
                                    
                                    <button className="buttonBackgroundSmall" disabled={!twitchOauthURL} onClick={() => { 
                                        logoutTwitch();
                                    }}>
                                    <span className="span">Logout</span></button>
                                    <button className="buttonBackgroundSmall" disabled={!twitchOauthURL} onClick={() => { 
                                        unlinkTwitch();
                                    }}>
                                    <span className="span">Unlink Account</span></button>
                                    <p>*Unlinking will prevent you from receiving any new drops in game.</p>
                                    </>
                                :
                                    <button className="buttonBackground" disabled={!twitchOauthURL} onClick={() => { 
                                        localStorage.setItem("plat", "/api/auth/twitch"); 
                                        window.location.href = twitchOauthURL.url;
                                }}
                                    ><img className="img" src="/images/twitch-logo.png"/><span className="span">Login to Twitch
                                    </span></button>
                                }
                            </div>
                    </Row >
                    
                    <Row className="p-3" id="steam">
                        <Container>
                            <div id="link">
                                <h4 className="pb-2 normalText"><b style={{color:"#FFE1AD"}}>Step 2</b>: Sign into your Platform account(s)</h4>
                                
                                {token != null && JSON.parse(token).steamid != null ?
                                    <>
                                     <img className="img" src="/images/steam-icon.png"/>
                                     <span className="span">{JSON.parse(token).steamid}</span>
                                     <button className="buttonBackgroundSmall" disabled={!steamAuthURL}
                                        onClick={() => { logoutSteam(); }}>
                                            <span className="span">Unlink Account</span>
                                    </button>
                                    </>
                                    :
                                    <>
                                    <button className="buttonBackground" disabled={token == null || !steamAuthURL}
                                    onClick={() => {
                                            localStorage.setItem("plat", "/api/auth/steam"); 
                                            window.location.href = steamAuthURL.url;
                                        } 
                                    }>
                                    <img className="img" src="/images/steam-icon.png"/>
                                    <span className="span">Login to Steam</span></button> 
                                    </>
                                }
                                <br></br>
                                {token != null && JSON.parse(token).xboxid != null ?
                                    <>
                                     <img className="img" src="/images/xbox-logo.png"/>
                                     <span className="span">{JSON.parse(token).xboxid}</span>
                                     <button className="buttonBackgroundSmall" disabled={!XBoxOauthURL}
                                        onClick={() => { logoutXBox(); }}>
                                            <span className="span">Unlink Account</span>
                                    </button>
                                    </>
                                    :
                                    <>
                                    <button className="buttonBackground" disabled={token == null || !XBoxOauthURL}
                                    onClick={() => {
                                            localStorage.setItem("plat", "/api/auth/xbox"); 
                                            window.location.href = XBoxOauthURL.url;
                                        } 
                                    }>
                                    <img className="img" src="/images/xbox-logo.png"/>
                                    <span className="span">Login to XBox</span></button> 
                                    </>
                                }
                                <br></br>
                                {token != null && JSON.parse(token).psid != null ?
                                    <>
                                     <img className="img" src="/images/playstation-logo.png"/>
                                     <span className="span">{JSON.parse(token).psid}</span>
                                     <button className="buttonBackgroundSmall" disabled={!playstationOauthURL}
                                        onClick={() => { logoutPlaystation(); }}>
                                            <span className="span">Unlink Account</span>
                                    </button>
                                    </>
                                    :
                                    <>
                                    <button className="buttonBackground" disabled={token == null || !playstationOauthURL}
                                    onClick={() => {
                                            localStorage.setItem("plat", "/api/auth/playstation"); 
                                            window.location.href = playstationOauthURL.url;
                                        } 
                                    }>
                                    <img className="img" src="/images/playstation-logo.png"/>
                                    <span className="span">Login to Playstation</span></button> 
                                    </>
                                }
                            <br></br>

                            {<>
                                <img className="img" src="/images/nintendo-logo.png"/>
                                <span className="span">Nintendo not Available</span>
                            </>
                            }

                            {/*token != null && JSON.parse(token).nid != null ?
                                    <>
                                     <img className="img" src="/images/nintendo-logo.png"/>
                                     <span className="span">{JSON.parse(token).nid}</span>
                                     <button className="buttonBackgroundSmall" disabled={!nintendoOauthURL}
                                        onClick={() => { logoutNintendo(); }}>
                                            <span className="span">Unlink Account</span>
                                    </button>
                                    </>
                                    :
                                    <>
                                    <img className="img" src="/images/nintendo-logo.png"/>
                                    <span className="span">Nintendo not Available</span>
                                    {}
                                    <button className="buttonBackground" disabled={token == null || !nintendoOauthURL}
                                    onClick={() => {
                                            localStorage.setItem("plat", "/api/auth/nintendo"); 
                                            window.location.href = nintendoOauthURL.url;
                                        } 
                                    }>
                                    <img className="img" src="/images/nintendo-logo.png"/>
                                    <span className="span">Login to Nintendo</span></button> }
                                    </>
                                */}

                            </div>
                        </Container>
                    </Row>
                    <Row className="p-3" id="status">
                        <Container>
                            <div>
                                <h4 className="pb-2 normalText"><b style={{color:status==2?"#00FF00":"#FF0000"}}>{statusValue[status]}
                               </b>: {statusMsg[status]}</h4>
                            </div>
                        </Container>
                    </Row>
                </Container>
            </Container>
            <Footer />
        </div>
    )
}

export function PageTitle(props){
    return(
        <Container>
            <div className="justify-content-center align-items-center headerText d-flex">
                <Image src="/images/bookendLeft.svg" className='leftDiamond'/>
                <div className="headerTitle">
                    {props.title}
                </div>
                <Image src="images/bookendRight.svg" className='rightDiamond'/>
            </div>
            {/*< p className='beta-warning'>This Twitch Drop Project is currently in Beta, please visit the <a href='https://discord.gg/projectwinter' className='discord-link'>Project Winter Discord</a> for more information.</p>*/}
        </Container>
    )
}

export function NavigationBar() {
    return (
        <Container fluid>
        <Row>
        <Container className="navBarContainer navbarContainerPadding">
            {/* As per Jons request 1200 and smaller we show the hamburger menu so
            We'll use bootstraps xl breakpoint since xl is 1200 and greater
            */}
            <Navbar variant="dark" className="navBarStyle" collapseOnSelect expand="xl">
                {/* This originally was a container and I switched it to a div with a class container-fluid, removed alot of the other classes.
                I need all the width I can get so I set this to container fluid so it uses the whole width of the screen.
                If it was just <container /> it be limited to whatever container size bootstrap was using (think max is 1400px wide)
                */}
                <div className="container-fluid nav-bar-center navbarContainerPadding">
                    {/* react-bootstrap has a proper Navbar.brand tag so we'll use it here with our image inside of it. */}
                    <Navbar.Brand className="navBarBrandImg">
                        <Image src="/images/WinterLogoLong_White_Large.png" className="float-start logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='response-navbar-nav'/>
                    <Navbar.Collapse className="navbar-links" id='response-navbar-nav'>
                        <Nav className="container-fluid w-100 d-flex justify-content-center ps-0 pe-0 nav-bar-link-position">
                            <Nav.Item>
                                <LinkContainer to="/">
                                    <Nav.Link className="ms-1 me-1">CURRENT DROPS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/pastdrops">
                                    <Nav.Link className="ms-1 me-1">PAST DROPS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/futureDrops">
                                    <Nav.Link className="ms-1 me-1">FUTURE DROPS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/faq">
                                    <Nav.Link className="ms-1 me-1">FAQ</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                            <Nav.Item>
                                <LinkContainer to="/linkaccounts">
                                    <Nav.Link className="ms-1 me-1">LINK ACCOUNTS</Nav.Link>
                                </LinkContainer>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </Container>
        </Row>
        </Container>
    )
}

export function Footer() {
    return (
        <Container fluid className="footer">
            <Row className ="justify-content-center py-4">
                <Col>
                    <Container className="imageContainer">
                        <Image src="/images/facebook.png" height="60px" width="60px" className="imageDefault"/>
                        <a href="https://www.facebook.com/projwinter/">
                            <Image src="/images/facebook-hover.png" height="60px" width="60px" className="imageHidden"/>
                        </a>
                    </Container> 
                </Col>
                <Col>
                    <Container className="imageContainer">
                        <Image src="/images/twitter.png" height="60px" width="60px" className="imageDefault"/>
                        <a href="https://twitter.com/projwinter">
                            <Image src="/images/twitter-hover.png" height="60px" width="60px" className="imageHidden"/>
                        </a>
                    </Container>
                </Col>
                <Col>
                    <Container className="imageContainer">
                        <Image src="/images/instagram.png" height="60px" width="60px" className="imageDefault"/>
                        <a href="https://www.instagram.com/projwinter/">
                           <Image src="/images/instagram-hover.png" height="60px" width="60px" className="imageHidden"/> 
                        </a>
                    </Container>
                </Col>
                <Col>
                    <Container className="imageContainer">
                        <Image src="/images/reddit.png" height="60px" width="60px" className="imageDefault"/>
                        <a href="https://www.reddit.com/r/Project_Winter/">
                           <Image src="/images/reddit-hover.png" height="60px" width="60px" className="imageHidden"/> 
                        </a>
                    </Container>
                </Col>
                <Col>
                    <Container className="imageContainer">
                        <Image src="/images/discord.png" height="60px" width="60px" className="imageDefault"/>
                        <a href="https://discord.com/invite/projectwinter">
                            <Image src="/images/discord-hover.png" height="60px" width="60px" className="imageHidden"/>
                        </a>
                    </Container>
                </Col>
                <Col>
                    <Container className="imageContainer">
                        <Image src="/images/mail.png" height="60px" width="60px" className="imageDefault"/>
                        <a href="https://otherocean.us15.list-manage.com/subscribe?u=5acaca8ecc5599f7e55e6d3ef&id=302de80b05">
                            <Image src="/images/mail-hover.png" height="60px" width="60px" className="imageHidden"/>
                        </a>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export function CountDownDisplay({timeLeft=[]}){
    return(
        <Row className="m-auto d-flex justify-content-center">
            <Col className="m-2 countDownColumn">
                <Row>
                    <Container className="d-flex justify-content-center text-light countDownRowHeader">
                        DAYS
                    </Container> 
                </Row>
                <Row>
                    <Container className="d-flex justify-content-center text-light countDownRowNumber">
                        {timeLeft.days}
                    </Container> 
                </Row>
            </Col>
            <Col className="m-2 countDownColumn">
                <Row>
                    <Container className="d-flex justify-content-center text-light countDownRowHeader">
                        HOURS
                    </Container> 
                </Row>
                <Row>
                    <Container className="d-flex justify-content-center text-light countDownRowNumber">
                        {timeLeft.hours}
                    </Container> 
                </Row>
            </Col>
            <Col className="m-2 countDownColumn">
                <Row>
                    <Container className="d-flex justify-content-center text-light countDownRowHeader">
                        MINUTES
                    </Container> 
                </Row>
                <Row>
                    <Container className="d-flex justify-content-center text-light countDownRowNumber">
                        {timeLeft.minutes}
                    </Container> 
                </Row>
            </Col>
            <Col className="m-2 countDownColumn">
                <Row>
                    <Container className="d-flex justify-content-center text-light countDownRowHeader">
                        SECONDS
                    </Container> 
                </Row>
                <Row>
                    <Container className="d-flex justify-content-center text-light countDownRowNumber">
                        {timeLeft.seconds}
                    </Container> 
                </Row>
            </Col>
        </Row>
    )
}