import './App.css';
import { useState, useEffect } from 'react';
import { Routes, Route} from 'react-router-dom';
import { Container, Row, Image, CardGroup, Card, Button} from 'react-bootstrap';
import { BsFillClockFill, BsDiamondFill } from "react-icons/bs"; 
import { Current, Past, Future, FAQS, Accounts, CountDownDisplay } from "./pages";

function App() {
  const [twitchDrops, setTwitchDrops] = useState([]);
  const [pastDrops, setPastDrops] = useState([]);
  const [futureDrops, setFutureDrops] = useState([]);
  const [faqs, setFAQS] = useState([]);
  
  useEffect(() => {
    fetch('/api/currentTwitchDrops')
      .then((response) => response.json())
      .then(setTwitchDrops)
  }, []);

  useEffect(() => {
    fetch('/api/pastTwitchDrops')
      .then((response) => response.json())
      .then(setPastDrops)
  }, []);

  useEffect(() => {
    fetch('/api/futureTwitchDrops')
      .then((response) => response.json())
      .then(setFutureDrops)
  }, []);

  useEffect(() => {
    fetch('/api/faqs')
      .then((response) => response.json())
      .then(setFAQS)
  }, []);

  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Current twitchDrops={twitchDrops}/>}/>
          <Route path="/pastdrops" element={<Past pastDrops={pastDrops}/>}/>
          <Route path="/futureDrops" element={<Future futureDrops={futureDrops}/>}/>
          <Route path="/faq" element={<FAQS faqs={faqs}/>}/>
          <Route path="/linkaccounts" element={<Accounts/>} />
        </Routes>

    </div>
  );
}

export function calculateTimeRemaining(startDate){
  let timeLeft = {};
  if(startDate.length == 0){
    timeLeft = {days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00"
    }
  } else {
    let difference = Number(new Date(startDate)) - Number(new Date());
    if (difference > 0) {
      timeLeft = {
        days: addZero(Math.floor(difference / (1000 * 60 * 60 * 24))),
        hours: addZero(Math.floor((difference / (1000 * 60 * 60)) % 24)),
        minutes: addZero(Math.floor((difference / 1000 / 60) % 60)),
        seconds: addZero(Math.floor((difference / 1000) % 60))
      };
    } else {
      return null;
    }
  }
  return timeLeft;
}

function addZero(time){
  if(time < 10){
    time = "0" + time
  }
  return time
}
    
export function TwitchDrops(props) {
  return (
    <Container>
      <CardGroup className="dropCard">
        <Card className="dropCardBackground">
          <Card.Body>
            <Card.Title className="p-2 text-light dropCardHeader">{(props.info.item_name).toUpperCase()}</Card.Title>
            <Card.Img variant="top" src={props.info.item_icon} />
            <Card.Text className="d-flex justify-content-center m-auto pt-2">
              <BsFillClockFill className="m-auto ms-0 me-0 pe-1" color="rgba(43, 36, 82, 0.75)" size="25px"/>
              <span className="m-auto ms-0 me-0" style={{color:"#2B2452"}}>{props.info.unlock_condition}</span>
            </Card.Text>
            {props.eventStatus === "current" && (
              <Card.Text className="d-flex justify-content-center m-auto pt-2">
                  <Button
                    style={{backgroundColor:"#822DFF", borderRadius:"6px", height:"55px", maxWidth:"250px", width:"100%", fontFamily:"Timeless-Normal", alignItems:"center"}}
                    variant="outline-*"
                    onClick = {() => window.location.href = props.info.streamer_account}
                  >
                    <Container className="d-flex justify-content-center p-0 m-0" style={{alignItems:"center", alignItems:"center", color:"white"}}>
                        <Image className="me-2" src="/images/twitchLogo.png" height="25px" width="25px"/>
                        <span style={{fontSize:"18px"}}>{props.info.streamer_name}</span>
                    </Container>
                  </Button>
              </Card.Text>
            )}
          </Card.Body>
         </Card>
      </CardGroup>
    </Container>
  )
}

export function Events(props){
  let today = new Date();
  let timeLeft = null;
  let startDate = new Date(props.info.start_date);
  let endDate = new Date(props.info.end_date);
  let eventStatus = "";

  if ((new Date(props.info.start_date) <= today) && (new Date(props.info.end_date) >= today)){
    timeLeft = calculateTimeRemaining(props.info.end_date)
    eventStatus = "current";
  } else {
    if (Math.floor((new Date(props.info.start_date) - today)/ (1000 * 60 * 60 * 24)) <= 999){
      timeLeft = calculateTimeRemaining(props.info.start_date);
    }
  }

  return(
    <Container className="countAndDrops">
      <Container className="rounded-3 backgroundTwitchCountdown pb-2">
      { eventStatus == "" && <h3 style={{ display: "inline"}}>{props.info.name}</h3> }
      { eventStatus == "" && <p className="m-auto yellowTextColor" style={{paddingLeft: "20px", fontSize:"20px", display: "inline"}}>{props.info.start_date} - {props.info.end_date}</p>}
          {timeLeft != null && (
            <>
              {/*((new Date(props.info.start_date) <= today) && (new Date(props.info.end_date) >= today)) ? 
              <p className="yellowTextColor" style={{fontSize:"20px"}}>Event Ends In:</p> : <p className="yellowTextColor" style={{fontSize:"20px"}}>Event Begins In:</p>
          */}
              <CountDownDisplay timeLeft={timeLeft}/>
            </>
          )}    
      </Container>
      <Row xs={1} md={3} className="g-4 justify-content-center">
        { props.info.drops.map((twitchDrop, i) => { return <TwitchDrops key={i} info={twitchDrop} eventStatus={eventStatus}/>}) }   
      </Row>
    </Container>
  )
} 

export function FAQ(props){
  return(
    <Container className="p-5" style={{background:"url(/images/main-image-alpha-snow.png)", backgroundSize:"100% 100%", verticalAlign:"middle"}}>
      <Row className="p-3">
        <h5 className="pb-2 yellowTextColor">{props.info.question}</h5>
        <Container className="pb-3">
          <BsDiamondFill color="#2B2452"/><BsDiamondFill color="#2B2452"/><BsDiamondFill color="#2B2452"/><BsDiamondFill color="#2B2452"/><BsDiamondFill color="#2B2452"/>
        </Container>
        <p style={{overflowWrap:"break-word", color:"white"}}>{props.info.answer}</p>
      </Row>
    </Container>
  )
}

export default App;